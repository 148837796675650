import { useContext, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
  CarLoanContext,
  DealershipContext,
  DealershipGroupContext,
  ManufacturerContext,
  TestDriveContext,
  WebsiteContext,
} from './contexts'

/**
 * Explanation: The context providers are set up outside of the Routes component in App.js.
 * So we wait until the child components have loaded before we fetch the full JSON objects,
 * because the child components have access to the Route params (useParams) and the context providers.
 */
export const useFetchDealership = (dealershipId = null) => {
  const { dealershipSlug } = useParams()
  const { websiteSlug } = useParams()
  const { dealership, setDealership } = useContext(DealershipContext)
  const [loading, setLoading] = useState(!dealership)

  const fetchDealership = useMemo(
    () => async () => {
      if (
        (dealership &&
          [dealership?.slug, String(dealership?.id)].includes(String(dealershipSlug))) ||
        (!dealershipSlug && !dealershipId && !websiteSlug)
      ) {
        setLoading(false)
        return
      }
      setLoading(true)

      try {
        if (dealershipSlug || dealershipId) {
          const response = await fetch(`/dealerships/${dealershipSlug || dealershipId}.json`)
          const data = await response.json()
          setDealership(data)
        } else if (websiteSlug) {
          const websiteResponse = await fetch(`/websites/${websiteSlug}.json`)
          const websiteData = await websiteResponse.json()
          const dealershipResponse = await fetch(`/dealerships/${websiteData.dealership_id}.json`)
          const dealershipData = await dealershipResponse.json()
          setDealership(dealershipData)
        }
      } catch (error) {
        console.error('Error fetching dealership data:', error)
      } finally {
        setLoading(false)
      }
    },
    [dealershipSlug, dealershipId, websiteSlug, setDealership]
  )

  useEffect(() => {
    fetchDealership()
  }, [fetchDealership])

  return { dealership, loading }
}

export const useFetchWebsite = () => {
  const { websiteSlug } = useParams()
  // TODO: This context approach will be redundant now that we have React Query
  const { setWebsite } = useContext(WebsiteContext)

  const { data: website, isLoading: loading } = useQuery({
    queryKey: ['website', websiteSlug],
    queryFn: async () => {
      if (!websiteSlug) return null
      const response = await fetch(`/websites/${websiteSlug}.json`)
      const data = await response.json()
      setWebsite(data) // Still update context for backward compatibility
      return data
    },
    enabled: !!websiteSlug,
    staleTime: 10 * 60 * 1000,
  })

  return { website, loading }
}

export const useFetchManufacturer = () => {
  const { manufacturerSlug } = useParams()
  const { manufacturer, setManufacturer } = useContext(ManufacturerContext)
  const [loading, setLoading] = useState(!manufacturer)

  // Memoize the fetch function to maintain referential equality
  const fetchManufacturerData = useMemo(
    () => async () => {
      if ((manufacturer && manufacturerSlug === manufacturer.slug) || !manufacturerSlug) {
        setLoading(false)
        return
      }
      setLoading(true)

      try {
        const response = await fetch(`/manufacturers/${manufacturerSlug}.json`)
        const data = await response.json()
        setManufacturer(data)
      } catch (error) {
        console.error('Error fetching manufacturer data:', error)
      } finally {
        setLoading(false)
      }
    },
    [manufacturerSlug, setManufacturer]
  )

  useEffect(() => {
    fetchManufacturerData()
  }, [fetchManufacturerData])

  // Memoize the return value to maintain prevent unnecessary re-renders
  return useMemo(() => ({ manufacturer, loading }), [manufacturer, loading])
}

export const useFetchDealershipGroup = () => {
  const { dealershipGroupId } = useParams()
  const { dealershipGroup, setDealershipGroup } = useContext(DealershipGroupContext)
  const [loading, setLoading] = useState(!dealershipGroup)

  // Memoize the fetch function to maintain referential equality
  const fetchDealershipGroupData = useMemo(
    () => async () => {
      if (dealershipGroup || !dealershipGroupId) {
        setLoading(false)
        return
      }
      setLoading(true)

      try {
        const response = await fetch(`/dealership_groups/${dealershipGroupId}.json`)
        const data = await response.json()
        setDealershipGroup(data)
      } catch (error) {
        console.error('Error fetching dealership group data:', error)
      } finally {
        setLoading(false)
      }
    },
    [dealershipGroupId, setDealershipGroup]
  )

  useEffect(() => {
    fetchDealershipGroupData()
  }, [fetchDealershipGroupData])

  // Memoize the return value to maintain prevent unnecessary re-renders
  return useMemo(() => ({ dealershipGroup, loading }), [dealershipGroup, loading])
}

export const useFetchTestDrive = async () => {
  const params = useParams()
  const testDriveId = params.testDriveId || null
  const { testDrive, setTestDrive } = useContext(TestDriveContext)

  if (!testDrive && testDriveId) {
    try {
      const response = await fetch(
        `/dealerships/${window.dealer_slug}/test_drives/${testDriveId}.json`
      )
      const data = await response.json()
      setTestDrive(data)
    } catch (error) {
      console.error('Error fetching test drive data:', error)
    }
  }
}

export const useFetchCarLoan = async () => {
  const params = useParams()
  const carLoanId = params.carLoanId || null
  const { carLoan, setCarLoan } = useContext(CarLoanContext)

  if (!carLoan && carLoanId) {
    try {
      const response = await fetch(`/dealerships/${window.dealer_slug}/car_loans/${carLoanId}.json`)
      const data = await response.json()
      setCarLoan(data)
    } catch (error) {
      console.error('Error fetching car loan data:', error)
    }
  }
}
